import React from "react";
import { Outlet, Link } from "react-router-dom";
import { Typography, Grid, Button, Divider } from "@mui/material";

import { BannerImage } from "../common/BannerImage";

function HomePage() {
  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{ width: "100%", Height: "100%", margin: "0px" }}
      >
        <BannerImage />
        <Grid
          container
          spacing={2}
          sx={{
            width: "100%",
            padding: 0,
            margin: 0,
          }}
        >
          <Grid
            item
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                textAlign: "center",
                paddingTop: "2rem",
              }}
            >
              OCR PE Submissions
            </Typography>
            <Divider
              sx={{ width: "30%", alignSelf: "center", marginTop: "1rem" }}
            />
          </Grid>

          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              my: 4,
              mx: 1,
              pb: 5,
            }}
          >
            <Grid
              item
              xs={12}
              sm={4}
              sx={{ display: "flex", flexDirection: "column" , alignItems:"center"}}
            >
              <Typography variant="h6" sx={{ mb: 2 }}>
                Visit arrangements
              </Typography>
              <Typography variant="caption" sx={{ mb: 2 }}>
                Use this service to submit visit arrangement details. You can
                save and return to this form until you submit it to us.
              </Typography>
              <Typography variant="caption" sx={{ mb: 2 }}>
                If you need help when completing the form, or for any queries or
                amendments after you have submitted the form, please contact{" "}
                <Link href="#" color="#000" underline="always">
                  {"support@ocr.org.uk"}
                </Link>
              </Typography>
              <Button
                variant="contained"
                color="primary"
                sx={{ width: "fit-content", mt: 2 }}
                component={Link}
                to="/home"
              >
                Go to visit arrangements
              </Button>
            </Grid>
            {/* <Grid
              item
              xs={12}
              sm={4}
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <Typography variant="h6" sx={{ mb: 2 }}>
                Activity marks submission
              </Typography>
              <Typography variant="caption" sx={{ mb: 2 }}>
                Use this service to submit activity marks. You can save and
                return to this form until you submit it to us.
              </Typography>
              <Typography variant="caption" sx={{ mb: 2 }}>
                If you need help when completing the form, or for any queries or
                amendments after you have submitted the form, please contact{" "}
                <Link href="#" color="#000" underline="always">
                  {"support@ocr.org.uk"}
                </Link>
              </Typography>
              <Button
                variant="contained"
                color="primary"
                sx={{ width: "fit-content", mt: 2 }}
                disabled
              >
                Go to activity marks submission
              </Button>
              <Outlet />
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
export default HomePage;
