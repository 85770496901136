import React from 'react'
import { Typography, Box, Grid, Container, Link } from "@mui/material";
import svg from '../../assets/unauthorized.svg';


const UnAuthorizedAccess = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                //alignItems: 'center',
                minHeight: '90vh',
                flexDirection: "column", width: "100%"
            }}
        >
            <Container maxWidth="md">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h4" sx={{ mt: 5, mb: 1, fontWeight: "bold" }}  >
                            Unauthorized Access
                        </Typography>
                        <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                            If you need access to the <b>OCR PE Submissions</b> service, please contact your exams officer.
                    </Typography>
                        <Typography variant="h6" sx={{ mt: 1, mb: 1 }}  >
                            If you know you already have access to <b>OCR PE Submissions</b>, please contact OCR at{" "}  <Link href={'mailto:support@ocr.org.uk'} color="#000" underline="always">
                                {"support@ocr.org.uk"}  </Link>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <img
                            src={svg}
                            alt=""
                            sx={{ width: "100%", height: "21rem" }}
                        />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default UnAuthorizedAccess
