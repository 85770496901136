import { Routes, Route } from "react-router-dom";
import HomePage from "./components/pages/HomePage";
import VafForm from "./components/pages/VafForm";
import LandingPage from "./components/pages/LandingPage";
import PageNotFound from "./components/pages/PageNotFound";
import UnAuthorizedAccess from "./components/pages/UnAuthorizedAccess";
import { useContext } from "react";
import { UserContext } from "./context/UserContext";

export const AppRoute = () => {
  const { roles, businessStream, loading } = useContext(UserContext);

  const checkUserHasAllowedRolesAndStreams = () => {
    return (
      (roles.includes("EXAMS_OFFICER") ||
        roles.includes("PE_SUBMISSIONS_ACCESS")) &&
      (businessStream.includes("OCR") || businessStream[0] === "OCR")
    );
  };
  if (loading) {
    return <></>;
  }
  return (
    <>
      <Routes>
        {checkUserHasAllowedRolesAndStreams() ? (
          <>
            <Route exact path="/" element={<HomePage />} />
            <Route exact path="/home" element={<LandingPage />} />
            <Route exact path="/home/vaf" element={<VafForm />} />
            <Route exact path="/*" element={<PageNotFound />} />
          </>
        ) : (
          <Route exact path="/*" element={<UnAuthorizedAccess />} />
        )}
      </Routes>
    </>
  );
};
