import { Box, Button, Divider } from "@mui/material";
import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MainContext } from "../../context/Context";
const StepActions = ({
  isError,
  handleSave,
  handleNext,
  handleBack,
  handleSubmit,
  isSubmit,
  isSuccess,
  isFirst,
  enterCandidates,
}) => {
  const navigate = useNavigate();
  const {formValues}= useContext(MainContext)
  return (
    <>
      <Divider variant="fullWidth" sx={{ mt: 3 }} />
      <Box
        sx={{
          display: "flex",
          justifyContent: isSuccess ? "flex-end" : "space-between",
          mt: 3,
        }}
      >
        {isSuccess ? (
          <>
            {/* <Button
              variant="contained"
              color="secondary"
              sx={{ mr: 1, width: "30%" }}
            >
              Download Copy
            </Button> */}
            <Button
              variant="contained"
              color="success"
              sx={{ mr: 1, width: "30%" }}
              component={Link}
              to="/"
            >
              Continue
            </Button>
          </>
        ) : isFirst ? (
          <>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to="/home"
            >
              Back
            </Button>
            <Button
              variant="contained"
              sx={{ mr: 3, width: "30%" }}
              disabled={isError()}
              color="success"
              onClick={!isError() ? handleNext : () => null}
            >
              {enterCandidates === "Y" ? `Next` : `Submit`}
            </Button>
          </>
        ) : (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={handleBack}
              sx={{ mr: 1, width: "30%", color: "white" }}
            >
              Back
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                handleSave(formValues).then(res=>res && navigate("/home"))
              }}
              color="secondary"
              sx={{ mr: 1, width: "30%" }}
            >
              Save & Close
            </Button>
            {!isSubmit ? (
              <Button
                sx={{ width: "30%", background: "#1e6807", color: "white" }}
                variant="contained"
                disabled={isError()}
                onClick={!isError() ? handleNext : () => null}
              >
                Next
              </Button>
            ) : (
              <Button
                sx={{ width: "30%" }}
                variant="contained"
                color="success"
                disabled={isError()}
                onClick={()=>!isError() ? handleSubmit(formValues) : () => null}
              >
                Submit
              </Button>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default StepActions;
