import { createTheme } from "@mui/material/styles";
import { blueGrey } from "@mui/material/colors";

export const theme = createTheme({
  palette: {
    primary: {
      light: blueGrey[300],
      main: "#435A60",
      dark: blueGrey[700],
      darker: blueGrey[900],
    },
    secondary: {
      main: "#02029a",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: { fontSize: "0.7rem" },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: "#91969b",

          "&.Mui-completed": {
            color: "#090909",
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          height: "2rem",
          marginTop: "3px",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          height: "2rem",
          marginTop: "3px",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          textWrap: "nowrap",
        },
      },
    },
  },
});
