import {
  Typography,
  Button,
  Card,
  CardMedia,
  CardContent,
  Box,
} from "@mui/material";
import { useContext } from "react";
import { MainContext } from "../../context/Context";
import { useNavigate } from "react-router-dom";
const SubmittedPopup = ({ qual }) => {
  const navigate = useNavigate();
  const { formValues } = useContext(MainContext);
  return (
    <Card
      sx={{
        display: "flex",
        padding: "10px",
        marginTop: "10px",
        flexDirection: "column",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <CardContent sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
            {qual.qualificationDisplayName}
          </Typography>
          <Typography variant="caption" sx={{ mt: "15px" }}>
            We have already received your visit arrangements.
          </Typography>
          <Typography variant="caption" sx={{ mt: "15px" }}>
            If you have any queries related to submitted form, please contact
            support@ocr.org.uk
          </Typography>
          <Typography variant="caption" sx={{ mt: "15px" }}>
            Submitted by: {formValues.submission_person_name.value}
          </Typography>
          <Typography variant="caption" sx={{ mt: "15px" }}>
            Submitted on: {formValues.declaration_date.value}
          </Typography>
          {formValues &&
            formValues.submit === "Y" &&
            formValues.expected_entries_indicator === "N" && (
              <Button
                onClick={() => navigate("/home/vaf", { state: { name: qual.qualificationId.join(",") } })}
                variant="contained"
                sx={{ fontSize: "x-small", width: "5rem", mt: "15px" }}
              >
                Edit
              </Button>
            )}
        </CardContent>
      </Box>
      {/* <Box sx={{ display: "flex", alignItems: "center", pl: 1, pb: 1 }}>
          <Typography variant="caption"  component="div">
            If you have any queries related to submitted form, please contact
            support@ocr.org.uk
          </Typography>
        </Box> */}
    </Card>
  );
};
export default SubmittedPopup;
