import SubmittedPopup from "./SubmittedPopup"; // Import your custom component
import React, { useState, useContext } from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import { Typography, Grid, Button, Tooltip } from "@mui/material";
import { QualContext } from "../../context/QualContext";
import { MainContext } from "../../context/Context";
import axios from "axios";
import { UserContext } from "../../context/UserContext";
import { BannerImage } from "../common/BannerImage";
import { useAuth } from "react-oidc-context";

const LandingPage = () => {
  const [tooltipOpen, setTooltipOpen] = useState({
    "OCR Level 3 Advanced GCE": false,
    "OCR Level 1/Level 2 GCSE (9-1)": false,
    "Entry Level Certificate": false,
  });
  const auth = useAuth();
  const accessToken = auth.user?.access_token;
  const { qualification } = useContext(QualContext);
  const { centre_number, centre_name } = useContext(UserContext);
  const { handleData, handleRefresh } = useContext(MainContext);
  const navigate = useNavigate();

  const handleButtonClick = (event, value) => {
    // Toggle the tooltip open state for the clicked button
    handleRefresh(); //reset Context
    let url =
      process.env.REACT_APP_API_BASEURL +
      `getvafform?session_id=3379&centre_number=${centre_number}&centre_name=${centre_name}`;
    event.target.name
      .split(",")
      .map(q => (url = url + `&qualification_id=${q}`));
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(res => {
        handleData(res.data);
        return res;
      })
      .then(res => {
        if (res.data.submit === "Y") {
          const temp = tooltipOpen;
          for (const key in tooltipOpen) {
            if (key === value && temp[key] === false) {
              temp[key] = true;
            } else {
              temp[key] = false;
            }
          }
          setTooltipOpen(temp);
        } else {
          setTooltipOpen({
            "OCR Level 3 Advanced GCE": false,
            "OCR Level 1/Level 2 GCSE (9-1)": false,
            "Entry Level Certificate": false,
          });
          navigate("/home/vaf", { state: { name: event.target.name } });
        }
      })
      .catch(e => {
        console.log("err", e);
      });
  };
  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{ width: "100%", height: "100%", margin: "0px" }}
      >
        <BannerImage />
        <Grid
          container
          spacing={2}
          sx={{ margin: "0 auto", width: "80%", py: 2 }}
        >
          <Grid item xs={12} sx={{ my: 4 }}>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              Visit arrangements
            </Typography>
            <Typography variant="subtitle2" sx={{ mt: 2 }}>
              Select the qualification you wish to submit visit arrangement
              details for.
            </Typography>
            <Typography variant="subtitle2" >
            Please note this form will no longer be visible after it has been
            submitted. Please take any screenshots you require.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flex: "nowrap",
              height: `${Object.values(tooltipOpen)[0] ? "25rem" : "5rem"}`,
            }}
          >
            {qualification.map((qual, index) => (
              <Tooltip
                key={index}
                title={<SubmittedPopup qual={qual} />}
                arrow
                open={Boolean(tooltipOpen[qual.qualificationName])}
              >
                <Button
                  variant="contained"
                  color="primary"
                  name={qual.qualificationId}
                  onClick={event =>
                    handleButtonClick(event, qual.qualificationName)
                  }
                  sx={{ height: "fit-content" }}
                >
                  {qual.qualificationDisplayName}
                </Button>
              </Tooltip>
            ))}
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" component={Link} to="/">
              Back
            </Button>
          </Grid>
          <Outlet />
        </Grid>
      </Grid>
    </>
  );
};
export default LandingPage;
