import { createContext, useMemo } from "react";

export const QualContext = createContext({
  qualification: [],
});

export const QualProvider = ({ children }) => {
  const contextValue = useMemo(
    () => ({
      qualification: [
        {
          qualificationId: [440, 159],
          qualificationName: "OCR Level 3 Advanced GCE",
          qualificationDisplayName: "AS/A Level visit arrangements",
        },
        {
          qualificationId: [1787],
          qualificationName: "OCR Level 1/Level 2 GCSE (9-1)",
          qualificationDisplayName: "GCSE Level visit arrangements",
        },
        {
          qualificationId: [97],
          qualificationName: "Entry Level Certificate",
          qualificationDisplayName: "Entry Level visit arrangements",
        },
      ],
    }),
    []
  );
  return (
    <QualContext.Provider value={contextValue}>
      <>{children}</>
    </QualContext.Provider>
  );
};
