import React ,{useEffect} from "react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Header from "./components/common/Header";
import { theme } from "./utils/Theme";
import { BrowserRouter } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import { UserContextProvider } from "./context/UserContext";
import { AppRoute } from "./Routes";
import { QualProvider } from "./context/QualContext";
import { MainContextProvider } from "./context/Context";
import ErrorBoundary from "./components/common/ErrorBoundary";

const App = () => {
  const auth = useAuth();
  useEffect(() => {
    const handleAuthentication = async () => {
      if (
        !auth.isAuthenticated &&
        !auth.isLoading &&
        !auth.error &&
        !auth.activeNavigator
      ) {
        await auth.signinRedirect();
      }

      if (auth.error) {
        sessionStorage.clear();
        await auth.signinRedirect();
      }
    };

    handleAuthentication();
  }, [auth]);
  // if (
  //   !auth.isAuthenticated &&
  //   !auth.isLoading &&
  //   !auth.error &&
  //   !auth.activeNavigator
  // ) {
  //   auth.signinRedirect();
  // }

  // if (auth.error) {
  //   sessionStorage.clear();
  //   auth.signinRedirect();
  // }

  if (auth.isAuthenticated) {
    return (
      <ThemeProvider theme={theme}>
        <ErrorBoundary>
          <UserContextProvider>
            <MainContextProvider>
              <QualProvider>
                <CssBaseline />
                <BrowserRouter>
                  <Header />
                    <AppRoute />
                </BrowserRouter>
              </QualProvider>
            </MainContextProvider>
          </UserContextProvider>
        </ErrorBoundary>
      </ThemeProvider>
    );
  }
};

export default App;
