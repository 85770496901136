export const isText = /^[A-Z ]+$/i;
export const isEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const isPhone = /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4,6})$/; // us
export const isZip = /^[0-9]{5}([- /]?[0-9]{4})?$/; // us
export const isNumber = /^\d+$/;
export const variant = "outlined";
export const margin = "normal";
export const aLevelColumns = [
  "Males",
  "Females",
  "Off-site",
  "Filmed",
  "Outdoor facilities",
  "Indoor facilities",
  "Extra Males?",
  "Extra Females?",
];
export const aLevelVenueColumns = ["Males", "Females", "Off-site", "Filmed"];

export const gcseLevelColumns = [
  "Males",
  "Females",
  "Off-site",
  "Outdoor facilities",
  "Indoor facilities",
  "Extra Males?",
  "Extra Females?",
];
export const gcseLevelVenueColumns = ["Males", "Females", "Off-site"];

export const entryLevelColumns = ["Males", "Females", "Live or filmed?"];
export const entryLevelVenueColumns = ["Males", "Females", "Being filmed?"];

export const commonLabels = {
  portalTitle: "PE Portal",
  myAccount: "My Account",
  signOut: "Sign Out",
  myCambrigeLinkText: "Go to My Cambridge",
  switchCenter: "Switch centre",
};

export const static_fields = [
  "session_id",
  "session_name",
  "qualifications",
  "expected_entries_indicator",
  "submit",
  "centre_host_venue_indicator",
  "a_level_activities",
  "as_level_activities",
  "gcse_activities_individual",
  "gcse_activities_team",
  "entrylvl_activities_individual",
  "entrylvl_activities_team",
];

export const QualMapping = {
  ENTRY:"97",
  ALEVEL:"440",
  ASLEVEL:"159",
  GCSE:"1787"
}

export const labels = [
  "First Step",
  "Second Step",
  "Third Step",
  "Fourth Step",
  "Fifth Step",
  "Confirmation",
];
export const entryLabels = [
  "First Step",
  "Second Step",
  "Third Step",
  "Fourth Step",
  "Fifth Step",
  "Sixth Step",
  "Confirmation",
];
export const data = {
  session_id: "3379",
  session_name: "June 2024",
  qualifications: [],
  centre_number: "",
  centre_name: "",
  expected_entries_indicator: "",
  submit: "",
  moderation_contact_name: null,
  moderation_contact_role: null,
  moderation_contact_email: null,
  moderation_contact_telephone: null,
  moderation_contact_extension: null,
  unavailable_dates: null,
  A_level_indicator: null,
  AS_level_indicator: null,
  moderation_type: null,
  centre_host_venue_indicator: "",
  centre_venue: null,
  facilities_addl_info: null,
  moderator_addl_info: null,
  submission_person_name: null,
  declaration_date: null,
  a_level_activities: [],
  as_level_activities: [],
  gcse_activities_individual: [],
  gcse_activities_team: [],
  entrylvl_activities_individual: [],
  entrylvl_activities_team: [],
};